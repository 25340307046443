import { Queue } from "../DataStructures/Queue";
import { Song } from "../Config/Playlist";
import { debounce } from "lodash";

export type Point = {
	x: number;
	y: number;
};

type Box = {
	startPoint: Point;
	endPoint: Point;
	pixels: number;
};

type CallbackType = (...args: any[]) => void;

export class Glyph {
	__start_point: Point;
	__end_point: Point;
	__middle: Point;
	__padding: Box;
	__ctx: CanvasRenderingContext2D;
	public width: number;
	public height: number;

	constructor(ctx: CanvasRenderingContext2D) {
		this.__start_point = {
			x: 0,
			y: 0,
		};
		this.__end_point = {
			x: 0,
			y: 0,
		};
		this.__middle = {
			x: 0,
			y: 0,
		};
		this.__ctx = ctx;
		this.width = 0;
		this.height = 0;
		this.__padding = {
			startPoint: this.__start_point,
			endPoint: this.__end_point,
			pixels: 0,
		};
	}

	setMiddle(middle: Point, sizeX: number, sizeY: number = sizeX) {
		this.__middle = middle;
		this.__start_point = {
			x: middle.x - sizeX / 2,
			y: middle.y - sizeY / 2,
		};
		this.__end_point = {
			x: middle.x + sizeX / 2,
			y: middle.y + sizeY / 2,
		};
		this.width = sizeX;
		this.height = sizeY;
		this.setPadding();
	}

	setPadding(pixels: number = this.__padding.pixels) {
		let startPadPoint: Point = {
			x: this.__start_point.x + pixels,
			y: this.__start_point.y + pixels,
		};
		let endPadPoint: Point = {
			x: this.__end_point.x - pixels,
			y: this.__end_point.y - pixels,
		};
		this.__padding = {
			startPoint: startPadPoint,
			endPoint: endPadPoint,
			pixels: pixels,
		};
	}

	setPoints(start: Point, end: Point) {
		this.__start_point = start;
		this.__end_point = end;
		this.width = this.__end_point.x - this.__start_point.x;
		this.height = this.__end_point.y - this.__start_point.y;
		this.setPadding();
	}

	mouseUp(e: MouseEvent) {
		this.defaultAction();
	}

	draw() {}

	defaultAction: CallbackType = () => {};

	setDefaultAction(callback: CallbackType) {
		this.defaultAction = debounce(callback, 100);
	}
}

export class Button extends Glyph {
	protected __text: string;
	protected __color: string;
	protected __roundness: number;
	protected __bg: string;
	protected __state: boolean;
	protected _theta: number;

	constructor(
		ctx: CanvasRenderingContext2D,
		text: string = "",
		color: string = "black",
		bg: string = "transparent",
		roundness: number = 3
	) {
		super(ctx);
		this.__text = text;
		this.__color = color;
		this.__roundness = roundness;
		this.__bg = bg;
		this.__state = false;
		this._theta = 0;
	}

	override draw(): void {
		drawRoundedRect(
			this.__ctx,
			this.__start_point.x,
			this.__start_point.y,
			this.width,
			this.height,
			this.__roundness,
			this.__bg
		);
		printText(
			this.__ctx,
			this.__text,
			this.__color,
			this.__start_point.x,
			this.__start_point.y,
			this.width,
			this.height,
			this._theta
		);
	}

	override mouseUp(e: MouseEvent): void {
		this.__state = !this.__state;
		this.defaultAction(this.__state);
	}
}

export class PlaylistBtn extends Button {
	__id: NodeJS.Timeout | null;

	constructor(
		ctx: CanvasRenderingContext2D,
		text: string,
		color: string = "black",
		bg: string = "transparent",
		roundness: number = 3
	) {
		super(ctx, text, color, bg, roundness);
		this.__id = null;
	}

	override mouseUp(e: MouseEvent): void {
		if (this.__id) return;
		super.mouseUp(e);
		this.changeThetaUsingInterval();
	}

	private changeThetaUsingInterval() {
		let time = 50;
		let steps = 10;
		let deltaAngle = Math.PI / steps;
		let finalAngle = this._theta + Math.PI;
		this.__id = setInterval(() => {
			this._theta += deltaAngle;
			if (this._theta >= finalAngle) {
				if (this.__id) clearInterval(this.__id);
				this.__id = null;
				return;
			}
		}, time);
	}
}

class MusicButton extends Button {
	private __cover: HTMLImageElement;

	constructor(
		ctx: CanvasRenderingContext2D,
		img: string,
		text: string = "",
		color: string = "black",
		bg: string = "white",
		roundness: number = 10
	) {
		super(ctx, text, color, bg, roundness);
		this.__cover = new Image();
		this.__cover.src = img;
	}

	override draw(): void {
		drawRoundedRect(
			this.__ctx,
			this.__padding.startPoint.x,
			this.__padding.startPoint.y,
			this.__padding.endPoint.x - this.__padding.startPoint.x,
			this.__padding.endPoint.y - this.__padding.startPoint.y,
			10,
			this.__bg
		);
		this.__ctx.drawImage(
			this.__cover,
			this.__padding.startPoint.x + 10,
			this.__padding.startPoint.y + 7,
			20,
			20
		);

		this.__drawText();
	}

	private __drawText() {
		this.__ctx.save();
		this.__ctx.beginPath();

		this.__ctx.rect(
			this.__start_point.x,
			this.__start_point.y,
			this.width - 10,
			this.height
		);
		this.__ctx.clip();

		this.__ctx.font = "14px msgothic";
		this.__ctx.textAlign = "left";
		this.__ctx.textBaseline = "middle";
		this.__ctx.fillStyle = "black";

		this.__ctx.fillText(
			this.__text,
			this.__padding.startPoint.x + 35,
			this.__padding.startPoint.y + 17
		);

		this.__ctx.restore();
	}
}

class Grid extends Glyph {
	__elements: Glyph[];
	col: number;
	row: number;

	constructor(ctx: CanvasRenderingContext2D, col: number = 1, row: number = 1) {
		super(ctx);
		this.__elements = [];
		this.col = col;
		this.row = row;
	}

	setElements(elements: Glyph[]) {
		this.__elements = elements;
	}

	draw(): void {
		this.__ctx.save();

		this.__ctx.beginPath();
		this.__ctx.rect(
			this.__start_point.x,
			this.__start_point.y,
			this.width,
			this.height
		);
		this.__ctx.clip();

		this.__elements.forEach((gl: Glyph) => {
			gl.draw();
		});

		this.__ctx.restore();
	}

	moveGridElements() {
		let i = 0;
		let boxH = this.height / this.row;
		let boxW = this.width / this.col;
		for (const songGlyph of this.__elements) {
			let startPoint: Point = {
				x: this.__start_point.x + (i % this.col) * boxW,
				y: this.__start_point.y + Math.floor(i / this.col) * boxH,
			};

			let endPoint: Point = {
				x: this.__start_point.x + ((i % this.col) + 1) * boxW,
				y: this.__start_point.y + Math.floor(i / this.col + 1) * boxH,
			};
			songGlyph.setPoints(startPoint, endPoint);
			i++;
		}
	}

	mouseUp(e: MouseEvent): void {
		for (const el of this.__elements) {
			if (
				e.offsetX >= el.__padding.startPoint.x &&
				e.offsetX <= el.__padding.endPoint.x &&
				e.offsetY >= el.__padding.startPoint.y &&
				e.offsetY <= el.__padding.endPoint.y
			) {
				el.mouseUp(e);
				return;
			}
		}
	}
}

export class PlaylistViewer extends Glyph {
	__bgColor: string;
	onScreen: boolean;
	__grid: Grid;
	__changeSong: Function;

	constructor(
		ctx: CanvasRenderingContext2D,
		songs: Song[],
		bgColor: string,
		btnFunction: Function
	) {
		super(ctx);
		this.__grid = new Grid(ctx, 4, 3);
		let glyphs = this.createSongGlyphs(songs);
		this.__grid.setElements(glyphs);
		this.__changeSong = btnFunction;
		this.__bgColor = bgColor;
		this.onScreen = false;
	}

	override setMiddle(middle: Point, sizeX: number, sizeY?: number): void {
		super.setMiddle(middle, sizeX, sizeY);
		this.__grid.setPoints(this.__padding.startPoint, this.__padding.endPoint);
	}

	createSongGlyphs(songs: Song[]): Glyph[] {
		let output = [];
		for (let i = 0; i < songs.length; i++) {
			const song = songs[i];
			let songGlyph = new MusicButton(
				this.__ctx,
				song.albumCover,
				song.title,
				"black",
				"white"
			);
			songGlyph.setPadding(5);
			songGlyph.setDefaultAction(() => {
				this.__changeSong(i);
			});
			output.push(songGlyph);
		}
		return output;
	}

	move(endX: number) {
		let time = 10;
		let totalTime = 500;
		let steps = totalTime / time;
		let delta = endX - this.__middle.x;
		let stepX = delta / steps;
		let i = 0;

		let id = setInterval(() => {
			let newX = this.__middle.x + stepX;

			this.setMiddle({ x: newX, y: this.__middle.y }, this.width, this.height);
			this.__grid.moveGridElements();
			i++;
			if (
				i >= steps ||
				(stepX > 0 && newX >= endX) ||
				(stepX < 0 && newX <= endX)
			) {
				clearInterval(id);
				this.setMiddle(
					{ x: endX, y: this.__middle.y },
					this.width,
					this.height
				);
				this.__grid.moveGridElements();
			}
		}, time);
	}

	override draw(): void {
		drawRoundedRect(
			this.__ctx,
			this.__start_point.x,
			this.__start_point.y,
			this.width,
			this.height,
			5,
			this.__bgColor
		);
		this.__grid.draw();
	}

	override mouseUp(e: MouseEvent): void {
		this.__grid.mouseUp(e);
	}
}

export class VolumeSlider extends Glyph {
	__color: string;
	__audio: HTMLAudioElement;

	constructor(
		ctx: CanvasRenderingContext2D,
		audio: HTMLAudioElement,
		color: string
	) {
		super(ctx);
		this.__audio = audio;
		this.__audio.volume = 0.8;
		this.__color = color;
	}

	override draw() {
		this.__ctx.save();
		this.drawBackgroundFill();
		this.drawBackgroundSelection();
		this.__ctx.restore();
	}

	drawBackgroundFill() {
		this.__ctx.fillStyle = "white";
		this.__ctx.fillRect(
			this.__start_point.x,
			this.__start_point.y,
			this.width,
			this.height
		);
	}

	drawBackgroundSelection() {
		let actualVolume = this.__audio.volume;
		this.__ctx.fillStyle = this.__color;
		this.__ctx.fillRect(
			this.__start_point.x,
			this.__end_point.y,
			this.width,
			-this.height * actualVolume
		);
	}

	override mouseUp(e: MouseEvent) {
		let cursorPosition = (e.offsetY - this.__end_point.y) / -this.height;
		this.__audio.volume = cursorPosition;
	}
}

export class PlayButton extends Glyph {
	__state: boolean;

	constructor(ctx: CanvasRenderingContext2D) {
		super(ctx);
		this.__state = false;
	}

	override draw(): void {
		if (!this.__ctx) return;
		if (this.__state) {
			this.drawPauseButton();
		} else {
			this.drawPlayButton();
		}
	}

	drawPlayButton() {
		this.__ctx.fillStyle = "white";
		this.__ctx.beginPath();
		this.__ctx.moveTo(this.__start_point.x, this.__start_point.y);
		this.__ctx.lineTo(this.__start_point.x, this.__end_point.y);
		this.__ctx.lineTo(
			this.__end_point.x,
			(this.__start_point.y + this.__end_point.y) / 2
		);
		this.__ctx.fill();
	}

	drawPauseButton() {
		this.__ctx.fillStyle = "white";
		this.__ctx.beginPath();
		this.__ctx.moveTo(this.__start_point.x, this.__start_point.y);
		this.__ctx.lineTo(this.__start_point.x, this.__end_point.y);
		this.__ctx.lineTo(this.__start_point.x + 10, this.__end_point.y);
		this.__ctx.lineTo(this.__start_point.x + 10, this.__start_point.y);

		this.__ctx.moveTo(this.__start_point.x + 20, this.__start_point.y);
		this.__ctx.lineTo(this.__start_point.x + 20, this.__end_point.y);
		this.__ctx.lineTo(this.__start_point.x + 30, this.__end_point.y);
		this.__ctx.lineTo(this.__start_point.x + 30, this.__start_point.y);
		this.__ctx.fill();
	}

	changeState(newState: boolean) {
		this.__state = newState;
	}

	override mouseUp(e: MouseEvent): void {
		this.__state = !this.__state;
		this.defaultAction(this.__state);
	}
}

export class NextButton extends Glyph {
	__audio: HTMLAudioElement;
	__playlist: Array<any>;
	__currentSongIndex: number;

	constructor(
		ctx: CanvasRenderingContext2D,
		audio: HTMLAudioElement,
		playlist: Array<any>
	) {
		super(ctx);
		this.__audio = audio;
		this.__playlist = playlist;
		this.__currentSongIndex = 0;
	}

	override draw(): void {
		if (!this.__ctx) return;
		this.__ctx.fillStyle = "white";
		this.__ctx.beginPath();
		this.__ctx.moveTo(this.__start_point.x, this.__start_point.y);
		this.__ctx.lineTo(this.__end_point.x, this.__middle.y);
		this.__ctx.lineTo(this.__start_point.x, this.__end_point.y);
		this.__ctx.fill();

		this.__ctx.beginPath();
		this.__ctx.moveTo(this.__start_point.x + 10, this.__start_point.y);
		this.__ctx.lineTo(this.__end_point.x + 10, this.__middle.y);
		this.__ctx.lineTo(this.__start_point.x + 10, this.__end_point.y);
		this.__ctx.fill();
	}
}

export class PreviousButton extends Glyph {
	__audio: HTMLAudioElement;
	__playlist: Array<any>;
	__currentSongIndex: number;

	constructor(
		ctx: CanvasRenderingContext2D,
		audio: HTMLAudioElement,
		playlist: Array<any>
	) {
		super(ctx);
		this.__audio = audio;
		this.__playlist = playlist;
		this.__currentSongIndex = 0;
	}

	override draw(): void {
		if (!this.__ctx) return;
		this.__ctx.fillStyle = "white";
		this.__ctx.beginPath();
		this.__ctx.moveTo(this.__end_point.x, this.__start_point.y);
		this.__ctx.lineTo(this.__start_point.x, this.__middle.y);
		this.__ctx.lineTo(this.__end_point.x, this.__end_point.y);
		this.__ctx.fill();

		this.__ctx.beginPath();
		this.__ctx.moveTo(this.__end_point.x - 10, this.__start_point.y);
		this.__ctx.lineTo(this.__start_point.x - 10, this.__middle.y);
		this.__ctx.lineTo(this.__end_point.x - 10, this.__end_point.y);
		this.__ctx.fill();
	}
}

export class MusicCover extends Glyph {
	private musicCover: HTMLImageElement;
	private turningAngle: number;
	private isTurning: boolean;

	constructor(ctx: CanvasRenderingContext2D, img: any) {
		super(ctx);
		this.musicCover = new Image();
		this.musicCover.src = img;
		this.turningAngle = 0;
		this.isTurning = false;
	}

	setCover(img: HTMLImageElement) {
		this.musicCover = img;
	}

	draw() {
		this.__ctx.save();

		this.__ctx.translate(this.__middle.x, this.__middle.y);
		this.__ctx.rotate((this.turningAngle * Math.PI) / 180);
		this.__ctx.translate(-this.__middle.x, -this.__middle.y);

		this.__ctx.beginPath();
		this.__ctx.arc(
			this.__middle.x,
			this.__middle.y,
			this.width / 2,
			0,
			Math.PI * 2,
			true
		);
		this.__ctx.closePath();
		this.__ctx.clip();

		this.__ctx.drawImage(
			this.musicCover,
			this.__start_point.x,
			this.__start_point.y,
			this.width,
			this.height
		);

		this.__ctx.restore();

		this.__ctx.strokeStyle = "white";
		this.__ctx.lineWidth = 4;
		this.__ctx.stroke();

		if (this.isTurning) this.turningAngle += 1;
	}

	resume() {
		this.isTurning = true;
	}

	pause() {
		this.isTurning = false;
	}
}

export class Background extends Glyph {
	image: HTMLImageElement;

	constructor(
		ctx: CanvasRenderingContext2D,
		width: number,
		height: number,
		bg: any
	) {
		super(ctx);
		this.__start_point = {
			x: 0,
			y: 0,
		};
		this.__end_point = {
			x: width,
			y: height,
		};
		this.width = width;
		this.height = height;
		this.image = new Image();
		this.image.src = bg;
		this.image.onload = () => {
			this.draw();
		};
	}

	override draw(): void {
		if (!this.__ctx) return;
		const canvasAspect = this.width / this.height;
		const imageAspect = this.image.width / this.image.height;
		let drawWidth, drawHeight, offsetX, offsetY;

		if (canvasAspect > imageAspect) {
			drawWidth = this.width;
			drawHeight = this.width / imageAspect;
			offsetX = 0;
			offsetY = (this.height - drawHeight) / 2;
		} else {
			drawWidth = this.height * imageAspect;
			drawHeight = this.height;
			offsetX = (this.width - drawWidth) / 2;
			offsetY = 0;
		}

		this.__ctx.drawImage(this.image, offsetX, offsetY, drawWidth, drawHeight);
	}

	changeBackground(src: any): void {
		this.image.src = src;
	}
}

export class TitleSong extends Glyph {
	__text: string;
	__size: number;
	constructor(ctx: CanvasRenderingContext2D, text: string, size: number = 18) {
		super(ctx);
		this.__text = text;
		this.__size = size;
	}

	override draw(): void {
		if (!this.__ctx) return;

		this.__ctx.font = `${this.__size}px msgothic`;
		this.__ctx.fillStyle = "white";
		this.__ctx.textAlign = "center";
		this.__ctx.textBaseline = "middle";

		let text = `${this.__text}`;
		this.__ctx.fillText(text, this.__start_point.x, this.__start_point.y);
	}
}

export class AudioSpectrumViewer extends Glyph {
	private __audioContext: AudioContext;
	private __dataArray: Uint8Array;
	private __queueSlices: Queue<number>;
	private __sliceWidth: number;
	private __totalSpace: number;
	private __margin: number;
	private __limit: number;
	private __source: any = null;
	private __analyser: AnalyserNode;

	constructor(
		ctx: CanvasRenderingContext2D,
		audioContext: AudioContext,
		mediaElementSource: MediaElementAudioSourceNode
	) {
		super(ctx);
		this.__limit = 20;
		this.__margin = 10;
		this.__totalSpace = this.width / this.__limit;
		this.__sliceWidth = this.__totalSpace - this.__margin;
		this.__queueSlices = new Queue<number>(this.__limit);
		this.__audioContext = audioContext;
		this.__analyser = this.__audioContext.createAnalyser();
		this.__source = mediaElementSource;
		this.__source.connect(this.__analyser);
		this.__dataArray = new Uint8Array(this.__analyser.frequencyBinCount);
	}

	override setMiddle(middle: Point, sizeX: number, sizeY?: number): void {
		super.setMiddle(middle, sizeX, sizeY);
		this.__limit = 20;
		this.__margin = 5;
		this.__totalSpace = this.width / this.__limit;
		this.__sliceWidth = this.__totalSpace - this.__margin;
	}

	getDecibels(): number {
		this.__analyser.getByteFrequencyData(this.__dataArray);
		let sum = 0;
		for (let i = 0; i < this.__dataArray.length; i++) {
			sum += this.__dataArray[i];
		}
		const average = sum / this.__dataArray.length;
		const decibels = 20 * Math.log10(average / 255);
		return decibels;
	}

	draw(): void {
		const decibels = this.getDecibels();
		this.__queueSlices.enqueue(decibels);

		let i = 1;
		for (const db of this.__queueSlices) {
			this.drawSlice(i, db);
			i += 1;
		}
	}

	drawSlice(position: number, decibels: number): void {
		let [x, y] = this.getCoordinatesFromPosition(position);
		const amplifier = -350;
		const height = amplifier / decibels;
		y = y - height / 2;
		drawRoundedRect(this.__ctx, x, y, this.__sliceWidth, height, 2, "white");
	}

	getCoordinatesFromPosition(position: number): [number, number] {
		let x = this.__end_point.x - this.__totalSpace * position;
		let y = this.__middle.y;
		return [x, y];
	}
}

function printText(
	ctx: CanvasRenderingContext2D,
	text: string,
	color: string,
	x: number,
	y: number,
	width: number,
	height: number,
	theta: number = 0
): void {
	ctx.save();
	ctx.font = "16px PIXELITE";
	ctx.textAlign = "center";
	ctx.textBaseline = "middle";
	ctx.fillStyle = color;

	const textX = x + width / 2;
	const textY = y + height / 1.8;

	ctx.translate(textX, textY);
	ctx.rotate(theta);

	ctx.fillText(text, 0, 0);

	ctx.restore();
}

function drawRoundedRect(
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	radius: number,
	fillStyle?: string,
	strokeStyle?: string
): void {
	ctx.beginPath();
	ctx.moveTo(x + radius, y);
	ctx.arcTo(x + width, y, x + width, y + height, radius);
	ctx.arcTo(x + width, y + height, x, y + height, radius);
	ctx.arcTo(x, y + height, x, y, radius);
	ctx.arcTo(x, y, x + width, y, radius);
	ctx.closePath();

	if (fillStyle) {
		ctx.fillStyle = fillStyle;
		ctx.fill();
	}

	if (strokeStyle) {
		ctx.strokeStyle = strokeStyle;
		ctx.stroke();
	}
}

export class PlaylistSlider extends Glyph {
	private __audio: HTMLAudioElement;
	private __color: string;

	constructor(
		ctx: CanvasRenderingContext2D,
		audio: HTMLAudioElement,
		color: string
	) {
		super(ctx);
		this.__color = color;
		this.__audio = audio;
	}

	draw() {
		this.__ctx.save();
		this.drawBackgroundFill();
		this.drawPlayingState();
		this.__ctx.restore();
	}

	drawBackgroundFill() {
		this.__ctx.fillStyle = "white";
		this.__ctx.fillRect(
			this.__start_point.x,
			this.__start_point.y,
			this.width,
			this.height
		);
	}

	drawPlayingState() {
		let actualPosition = this.__audio.currentTime / this.__audio.duration;
		this.__ctx.fillStyle = this.__color;
		this.__ctx.fillRect(
			this.__start_point.x - 1,
			this.__start_point.y - 1,
			this.width * actualPosition + 1,
			this.height + 2
		);
	}

	changeColor(color: string): void {
		this.__color = color;
	}

	mouseUp(e: MouseEvent): void {
		let cursorPosition = (e.offsetX - this.__start_point.x) / this.width;
		let cursorPositionToSeconds = this.__audio.duration * cursorPosition;
		this.__audio.currentTime = cursorPositionToSeconds;
	}
}
