import { Window } from "../Window";
import {
	PlayButton,
	MusicCover,
	NextButton,
	PreviousButton,
	Background,
	TitleSong,
	AudioSpectrumViewer,
	PlaylistSlider,
	VolumeSlider,
	PlaylistViewer,
	PlaylistBtn,
} from "../Glyphs";

import { Playlist, Song } from "../../Config/Playlist";

export class CanvasMusicPlayer extends Window {
	private static instance: CanvasMusicPlayer | null = null;
	private audioPlaylist: Song[];
	private currentSong: Song;
	private coverGlyph!: MusicCover;
	private playButton!: PlayButton;
	private nextButton!: NextButton;
	private previousButton!: PreviousButton;
	private background!: Background;
	private textTitle!: TitleSong;
	private playlistBar!: AudioSpectrumViewer;
	private playlistSlicer!: PlaylistSlider;
	private volumeSlider!: VolumeSlider;
	private playlistViewer!: PlaylistViewer;
	private openPlaylistBtn!: PlaylistBtn;
	private currentSongIndex: number;
	private mediaElementSource: MediaElementAudioSourceNode;
	private audioContext: AudioContext;
	private audioInstance: HTMLAudioElement;

	private __bg: any;
	private __color: string;

	constructor(canvas: HTMLCanvasElement, bg: any, color: string) {
		super(canvas);
		this.__bg = bg;
		this.__color = color;
		this.audioPlaylist = Playlist;

		this.currentSongIndex = 0;
		this.currentSong = this.audioPlaylist[this.currentSongIndex];

		this.audioInstance = new Audio(this.currentSong.song);

		this.audioContext = new (window.AudioContext ||
			(window as any).webkitAudioContext)();

		this.mediaElementSource = this.audioContext.createMediaElementSource(
			this.audioInstance
		);

		this.mediaElementSource.connect(this.audioContext.destination);

		this.audioInstance.load();

		if (!this.ctx) return;
		this.coverGlyph = new MusicCover(this.ctx, this.currentSong.albumCover);
		this.playButton = new PlayButton(this.ctx);
		this.nextButton = new NextButton(
			this.ctx,
			this.audioInstance,
			this.audioPlaylist
		);
		this.previousButton = new PreviousButton(
			this.ctx,
			this.audioInstance,
			this.audioPlaylist
		);
		this.background = new Background(
			this.ctx,
			this.width,
			this.height,
			this.__bg
		);
		this.textTitle = new TitleSong(
			this.ctx,
			this.audioPlaylist[this.currentSongIndex].title
		);
		this.playlistBar = new AudioSpectrumViewer(
			this.ctx,
			this.audioContext,
			this.mediaElementSource
		);
		this.playlistSlicer = new PlaylistSlider(
			this.ctx,
			this.audioInstance,
			this.__color
		);
		this.volumeSlider = new VolumeSlider(
			this.ctx,
			this.audioInstance,
			this.__color
		);
		this.playlistViewer = new PlaylistViewer(
			this.ctx,
			Playlist,
			this.__color,
			this.playSongById
		);
		this.createElements();
		this.addAudioEventListeners();
	}

	public static getInstance(
		canvas: HTMLCanvasElement,
		bg: any,
		color: string
	): CanvasMusicPlayer {
		if (CanvasMusicPlayer.instance === null) {
			CanvasMusicPlayer.instance = new CanvasMusicPlayer(canvas, bg, color);
			CanvasMusicPlayer.instance.init();
		}
		CanvasMusicPlayer.instance.changeBackground(bg, color);
		return CanvasMusicPlayer.instance;
	}

	public changeBackground(src: any, color: string): void {
		this.background.changeBackground(src);
		this.playlistSlicer.changeColor(color);
	}

	override addElementsToResize(): void {
		this.createElements();
	}

	createElements() {
		this.__glyphs = [];
		Window.__mouseEvents.cleanup();
		this.createMusicCover();
		this.createButtons();
		this.createBackground();
		this.createTitle();
		this.createPlaylistBar();
		this.createVolumeBar();
		this.createPlaylistViewer();
		this.createButtonPlaylist();
	}

	resumeOrPlayMusic = (state: boolean) => {
		if (this.audioContext.state === "suspended") {
			this.audioContext.resume().then(() => {
				this.togglePlayState(state);
			});
		} else {
			this.togglePlayState(state);
		}
	};

	togglePlayState = (state: boolean) => {
		if (state) {
			this.audioInstance.play();
			this.coverGlyph?.resume();
		} else {
			this.audioInstance.pause();
			this.coverGlyph?.pause();
		}
	};

	playSongById = (i: number) => {
		if (i >= this.audioPlaylist.length) {
			this.currentSongIndex = 0;
		} else if (i < 0) {
			this.currentSongIndex = this.audioPlaylist.length - 1;
		} else {
			this.currentSongIndex = i;
		}

		this.audioInstance.src = this.audioPlaylist[this.currentSongIndex].song;
		this.textTitle.__text = this.audioPlaylist[this.currentSongIndex].title;
		this.audioInstance.load();
		this.resumeOrPlayMusic(true);
	};

	playNextSong = () => {
		this.playSongById(this.currentSongIndex + 1);
	};

	playPreviousSong = () => {
		if (this.audioInstance.currentTime > 3) {
			this.audioInstance.currentTime = 0;
			return;
		}

		this.playSongById(this.currentSongIndex - 1);
	};

	createButtons() {
		let middle = {
			x: this.width / 7,
			y: this.height / 2,
		};

		if (this.height > this.width / 2) {
			middle = {
				x: this.width / 2,
				y: this.height / 3,
			};
		}

		this.playButton.setMiddle(middle, 30);
		this.playButton.setDefaultAction(this.resumeOrPlayMusic);
		this.addGlyph(this.playButton);

		this.previousButton.setMiddle(
			{ x: this.coverGlyph.__start_point.x, y: middle.y },
			30
		);
		this.previousButton.setDefaultAction(this.playPreviousSong);
		this.addGlyph(this.previousButton);

		this.nextButton.setMiddle(
			{ x: this.coverGlyph.__end_point.x, y: middle.y },
			30
		);
		this.nextButton.setDefaultAction(this.playNextSong);
		this.addGlyph(this.nextButton);
	}

	createBackground() {
		if (!this.ctx) return;
		this.background = new Background(
			this.ctx,
			this.width,
			this.height,
			this.__bg
		);
		this.addBackground(this.background);
	}

	createTitle() {
		let middle = {
			x: (this.width / 7) * 4,
			y: (this.height / 8) * 7,
		};

		if (this.height > this.width / 2) {
			middle = {
				x: this.width / 2,
				y: (this.height / 8) * 7,
			};
		}

		this.textTitle.setMiddle(middle, 0);
		this.addGlyph(this.textTitle);
	}

	createPlaylistBar() {
		let middle = {
			x: (this.width / 7) * 4,
			y: this.height / 2,
		};
		let slicerMiddle = {
			x: middle.x,
			y: middle.y + 40,
		};

		let length = 300;
		if (this.height > this.width / 2) {
			middle = {
				x: this.width / 2,
				y: (this.height / 4) * 3,
			};
			slicerMiddle = {
				x: middle.x,
				y: middle.y - 40,
			};
			length = (this.width / 4) * 3;
		}

		this.playlistSlicer.setMiddle(slicerMiddle, length, 5);
		this.playlistBar.setMiddle(middle, length, 80);
		this.addGlyph(this.playlistBar);
		this.addGlyph(this.playlistSlicer);
	}

	createVolumeBar() {
		let middle = {
			x: this.width * 0.9,
			y: this.height / 2,
		};

		if (this.height > this.width / 2) {
			middle = {
				x: this.width / 1.25,
				y: this.height / 3,
			};
		}

		this.volumeSlider.setMiddle(middle, 8, 100);
		this.addGlyph(this.volumeSlider);
	}

	createPlaylistViewer() {
		let middle = {
			x: this.width + this.width / 2,
			y: this.height / 2,
		};
		let width = this.width * 0.9;
		let height = this.height * 0.9;
		this.playlistViewer.setMiddle(middle, width, height);
		this.playlistViewer.setPadding(15);
		this.addGlyph(this.playlistViewer);
	}

	switchPlaylistState = (state: boolean) => {
		if (state) {
			this.openPlaylist();
		} else {
			this.closePlaylist();
		}
	};

	openPlaylist = () => {
		this.playlistViewer.move(this.width / 2);
	};

	closePlaylist = () => {
		this.playlistViewer.move(this.width + this.width / 2);
	};

	createButtonPlaylist() {
		if (!this.ctx) return;
		this.openPlaylistBtn = new PlaylistBtn(
			this.ctx,
			"\u2190",
			"black",
			"white"
		);
		let middle = {
			x: this.width * 0.97,
			y: this.height * 0.13,
		};

		if (this.height > this.width / 2) {
			middle = {
				x: this.width * 0.85,
				y: this.height * 0.12,
			};
		}

		this.openPlaylistBtn.setMiddle(middle, 25, 25);
		this.openPlaylistBtn.setDefaultAction(this.switchPlaylistState);
		this.addGlyph(this.openPlaylistBtn);
	}

	createMusicCover() {
		let middle = {
			x: this.width / 7,
			y: this.height / 2,
		};

		if (this.height > this.width / 2) {
			middle = {
				x: this.width / 2,
				y: this.height / 3,
			};
		}
		this.coverGlyph.setMiddle(middle, 125);
		this.addGlyph(this.coverGlyph);
	}

	addAudioEventListeners() {
		this.audioInstance.addEventListener("play", () => {
			this.coverGlyph.resume();
			this.playButton.changeState(true);
		});

		this.audioInstance.addEventListener("pause", () => {
			this.coverGlyph.pause();
			this.playButton.changeState(false);
		});

		this.audioInstance.addEventListener("ended", () => {
			this.coverGlyph.pause();
			this.playButton.changeState(false);
			setTimeout(() => {
				this.playNextSong();
			}, 1500);
		});
	}

	getCurrentTime(): number {
		return this.audioInstance.currentTime;
	}

	setCurrentTime(seconds: number): void {
		this.audioInstance.currentTime = seconds;
	}

	override cleanup(): void {
		super.cleanup();
		this.audioInstance.pause();
		this.audioInstance.currentTime = 0;
		if (this.mediaElementSource) {
			this.mediaElementSource.disconnect();
		}
	}
}
