import { useState, useCallback, useRef, useEffect, MutableRefObject, Fragment } from 'react'

import { useTheme } from '../../../Context/Themes'
import { useLanguage } from '../../../Context/LanguageContext'
import { useLogin } from '../../../Context/Login'

import SendIcon from '../../../Assets/Img/SVG/AIChat/send_button.svg'
import ArrowIcon from '../../../Assets/Img/SVG/AIChat/arrow_icon.svg'
import LoadingIcon from '../../../Assets/Img/SVG/AIChat/loading_icon.svg'

import CatIcon from '../../../Assets/Img/AI/cat_chat_icon.png'
// import ChatBubble from '../../../Assets/Img/AI/chat_bubble.png'

import axios from 'axios'

export default function AIChat() {
    const { login, setLogin } = useLogin()
    const { theme } = useTheme();
    const [isOpen, setIsOpen] = useState(false)

    const chatFrameRef = useRef(null)

    const toggleChat = () => {
        setIsOpen(!isOpen)
    }

    const loginCall = useCallback(() => {
        const LOGIN_URL = process.env.REACT_APP_LOGIN_API_URL;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        axios({
          method: 'GET',
          url: LOGIN_URL,
          headers: {
            'Content-Type': 'application/json',
            'Timezone': timezone,
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        }).then((res) => {
          if (res.status === 201) {
            localStorage.setItem('token', res.data.token)
          }
          setLogin(true)
        }).catch((err) => {
          console.log(err)
          setLogin(false)
        })
      }, [setLogin])
    
    useEffect(loginCall, [loginCall])

    return (
        login ? (
            <div className={`fixed bottom-8 max-sm:left-0 max-sm:px-4 sm:right-20 ${isOpen ? '-translate-y-0' : 'translate-y-full'} w-fit h-3/4 sm:h-96 z-20 transition-transform duration-1000 ease-in-out`} ref={chatFrameRef}>
            <div className={`w-fit h-full flex flex-col justify-end rounded-t-lg`}>
                <div className={`w-32 self-end h-8 flex-none flex justify-center items-center gap-2 border-b-2 border-white relative ${theme.primary.bgColor} rounded-t-lg`}>
                    <img onClick={toggleChat} src={CatIcon} className='absolute -top-20 w-fit h-24 cursor-pointer' alt='cat pixel art'></img>
                    <div onClick={toggleChat} className='w-full h-auto flex justify-center cursor-pointer'>
                        <img src={ArrowIcon}  className={`w-5 h-5 transition-transform duration-500 ease-in-out ${isOpen ? 'rotate-180' : 'rotate-0'} ${theme.misc.style === 'oldMacStyle' ? 'invert' : ''}`} alt='arrow icon'></img>
                    </div>
                </div>
                <ChatUI isOpen={isOpen} setIsOpen={setIsOpen} chatFrameRef={chatFrameRef}/>
            </div>
        </div>
        ) : null
    )
}   

interface ChatUIType {
    isOpen: boolean,
    setIsOpen: Function,
    chatFrameRef: MutableRefObject<HTMLDivElement | null>
}

function ChatUI({ isOpen, setIsOpen, chatFrameRef } : ChatUIType){
    const { theme } = useTheme();
    const { lang } = useLanguage();

    const [isSending, setIsSending] = useState(false)
    const [messages, setMessages] = useState([{message: lang.aiChat.chat, assistant: true}])

    const chatMessagesRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        function recoverMessagesFromLocalStorage() {
            let messagesRAW = localStorage.getItem('messages');
            if (messagesRAW) {
                let messages = JSON.parse(messagesRAW)
                setMessages([{message: lang.aiChat.chat, assistant: true}, ...messages])
            }
        }
        recoverMessagesFromLocalStorage()
    }, [lang])

    useEffect(() => {   
        function saveMessagesIntoLocalStorage() {
            if (messages.length === 1) return
            localStorage.setItem('messages', JSON.stringify(messages.slice(1)))
        }

        saveMessagesIntoLocalStorage()
    }, [messages]);

    useEffect(() => {
        if (isOpen) {
            if (!chatMessagesRef.current) return;
            chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight
        }
    }, [isOpen, messages])

    useEffect(() => {
        const handleClickOutside = (event : MouseEvent) => {
            if (chatFrameRef.current && !chatFrameRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [chatFrameRef, setIsOpen])

    return(
        <Fragment>
            <div className={`h-full w-fit sm:w-72 flex-1 flex flex-col gap-2 rounded-tl-lg justify-start overflow-y-auto p-3 ${theme.primary.bgColor} ${theme.primary.textColor}`} ref={chatMessagesRef}>
                { messages.map((msg, index) => <MessageBubble key={index} message={msg.message} assistant={msg.assistant}/>) }
                { isSending && <IsWritting/> }
            </div>
            <UserInterface isSending={isSending} setIsSending={setIsSending} messages={messages} setMessages={setMessages}/> 
        </Fragment>
    )
}

interface MessageBubbleType {
    message: string,
    assistant: boolean
}

function MessageBubble(props : MessageBubbleType){
    const { theme } = useTheme();

    return(
        <div className={`w-full h-fit flex ${props.assistant ? 'justify-start' : 'justify-end'}`}>
            <p 
            className={`h-fit w-fit max-w-96 rounded-t-md p-1 ${props.assistant ? 'rounded-r-md text-start' : 'rounded-l-md text-end'} ${theme.primary.bgTextColor} px-2 text-md break-words`}>
              { props.message }  
            </p>
        </div>
    )
}

function IsWritting(){
    const dot1 = useRef<HTMLDivElement | null>(null)
    const dot2 = useRef<HTMLDivElement | null>(null)
    const dot3 = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        let dots = [dot1, dot2, dot3]

        const interval = setInterval(() => {
            dots.forEach((dot, index) => {
                setTimeout(() => {
                    if (dot.current) {
                        dot.current.style.transform = 'translateY(-3px)';
                    }
                }, 100 * index)
                setTimeout(() => {
                    if (dot.current) {
                        dot.current.style.transform = 'translateY(0px)'
                    }
                }, 100 * index + 100)
            })
        }, 500)

        return () => {
            clearInterval(interval)
        }
    }, [dot1, dot2, dot3])

    return(
        <div className='w-full h-8 flex justify-start items-center gap-1'>
            <div className='w-2 h-2 rounded-full bg-white' ref={dot1}></div>
            <div className='w-2 h-2 rounded-full bg-white' ref={dot2}></div>
            <div className='w-2 h-2 rounded-full bg-white' ref={dot3}></div>
            <p className='text-sm ml-2'>Nervesscat is typing...</p>
        </div>
    )
}

interface UserInterfaceType {
    isSending: boolean, setIsSending: Function, 
    messages: Array<{ message: string, assistant: boolean }>, 
    setMessages: Function 
}

function UserInterface({ isSending, setIsSending, messages, setMessages } : UserInterfaceType) {
    const { theme } = useTheme();
    const [input, setInput] = useState('');
    const [errorMessages, setErrorMessages] = useState('');

    const addUserMessage = () => {
        if (input === '') return;
        const newMessage = {message: input, assistant: false};
        setInput('');
        addMessage(newMessage.message, newMessage.assistant);
        getAIResponse(newMessage);
    }

    const addMessage = (msg : string, assistant : boolean) => {
        setMessages((prev : Array<{message: string, assistant: boolean}>) => {
            return [...prev, {message: msg, assistant: assistant}]
        })
    }

    function getAIResponse(newMessage : {message: string, assistant: boolean}) {
        const AI_URL = process.env.REACT_APP_AI_API_URL
        let sendMessages = [...messages, newMessage].slice(-20)
        setIsSending(true)
        axios({
            method: 'POST',
            url: AI_URL,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: {
                messages: sendMessages
            }
        }).then((res) => {
            setIsSending(false)
            if (res.status === 200) {
                addMessage(res.data.response, true)
            } else if (res.status === 401) {
                setErrorMessages('Credentials expected, please reload the page')
            } else if (res.status === 500) {
                setErrorMessages('An error has occurred, please try again')
            }
        }).catch((err) => {
            setIsSending(false)
            setErrorMessages('An error has occurred, please try again')
            console.log(err)
        })
    }

    const updateInput = (e : React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);
    }

    return(
        <Fragment>
            <p className={`text-sm px-3 text-red-400 ${theme.primary.bgColor} ${ errorMessages.length > 0 ? '' : 'invisible' }`}>{ errorMessages }</p>
            <div className={`w-full h-14 p-2 flex-none flex justify-center items-center gap-2 rounded-b-lg ${theme.primary.bgColor}`}>
                <input placeholder='Type /help' 
                className={`w-full h-full rounded-lg ${theme.primary.bgTextColor} px-2 text-md`} 
                onChange={updateInput}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        addUserMessage()
                    }
                }}
                value={input}
                ></input>
                <button 
                    className={`w-10 h-full rounded-xl ${theme.primary.bgTextColor} ${theme.primary.borderColor} flex items-center justify-center`}
                    onClick={() => addUserMessage()}
                    disabled={isSending}
                    type='button'
                >   
                { isSending ? <img src={LoadingIcon} className='w-5 h-5 animate-spin' alt='loading icon'></img> : <img src={SendIcon} className={`w-5 h-5 ${theme.misc.style === 'oldMacStyle' ? 'invert' : ''}`} alt='send button'></img> }                        
                </button>
            </div>
        </Fragment>
    )
}
